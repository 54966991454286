import restApiConnection from '@/services/api/providers/Connection'
import vehiclesResponse from '@/types/responses/vehiclesResponse'
import vehicleInfo from '@/types/vehicleInfo'
import RestApiBase from '@/services/models/RestApiBase'
import UserSession from '@/services/models/UserSession'

export default class Vehicle extends RestApiBase {
  public static async all(
    forceUpdate: boolean = false,
    callback: Function = (vehicles: vehicleInfo[]) => {
      return vehicles
    }
  ): Promise<vehiclesResponse> {
    return this.sync('GetVehicles', () => {
      return this.selectAll({ cache: !forceUpdate, callback: callback })
    })
  }

  public static selectAll = async (args: {
    cache: boolean
    callback: Function
  }): Promise<vehiclesResponse> => {
    const user_id = UserSession.getStoredUserId()

    const limitPerPage = 20
    let maxPageCount = 1
    let vehicles: vehicleInfo[] = []

    for (let page = 1; page <= maxPageCount; page++) {
      const vehiclesApi = await restApiConnection.get('/vehicles', {
        headers: {
          'Cache-Control': 'max-age=0',
        },
        cache: args.cache,
        params: {
          page: page,
          limit: limitPerPage,
          user_session_id: UserSession.getStoredUserId(),
        },
      })

      const totalPages = vehiclesApi?.data?.total_pages ?? 1
      const data = vehiclesApi?.data?.vehicles ?? []
      maxPageCount = totalPages

      if (data.length === 0) {
        break
      }

      vehicles = vehicles.concat(data)
      args.callback(data)
    }
    return new Promise((resolve) => {
      resolve({ data: vehicles } as vehiclesResponse)
    })
  }
}
